import React from 'react'

const Logos = () => {
  return (
      <div className='py-10 w-full flex justify-center bg-black/20 items-center'>
          <div className='flex items-center space-x-32'>
              {/* image 1 */}
              {/* image 2 */}
        </div>
    </div>
  )
}

export default Logos